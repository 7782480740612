@import "/src/app/styles";

.content {
	width: 100vw;
}

.top {
	width: 100vw;
	background-image: url("../../modules/gamingEmpire/assets/img/png/backgroundGamingEmpireCore.png") !important;
	background-repeat: no-repeat;
	background-size: cover;
}

.bottom {
	width: 100vw;
	background-image: url("../../modules/ourGames/assets/img/png/backgroundOurGamesCore.png") !important;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	@include mediaMax(590px) {
		background-image: url("../../modules/ourGames/assets/img/png/backgroundOurGamesCoreMobile.png") !important;
		background-size: contain;
	}
}

.torch {
	margin-top: -10px;

	@include mediaMax(590px) {
		height: 180px;
		width: auto;

		margin-top: -9px;
	}
}

.core {
	margin-top: 40px;

	@include mediaMax(590px) {
		margin-top: 17px;
	}
}

.map {
	margin-top: 2px;

	@include mediaMax(590px) {
		height: 140px;
		width: auto;

		margin-top: 11px;
	}
}

.card1 {
	background-image: url("../../modules/common/assets/img/svg/shadowNoGasFeesCore.svg") !important;
}

.card2 {
	background-image: url("../../modules/common/assets/img/svg/shadowOurMissionCore.svg") !important;
}

.card3 {
	background-image: url("../../modules/common/assets/img/svg/shadowWhatWeDoCore.svg") !important;
}
