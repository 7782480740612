@import "/src/app/styles";

.content {
	width: 145px;
	height: 46px;

	display: grid;
	justify-items: center;
	align-items: center;

	position: relative;
}

.border {
	cursor: pointer;

	position: absolute;

	top: 0;
	left: 0;

	width: 147px;
	height: 48px;
}

.text {
	font-family: $mainFontFamily;
	font-weight: 600;
	font-size: 16px;
	line-height: 110%;
	text-align: center;
	color: #fff;
}
