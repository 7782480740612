@import "/src/app/styles";

.content {
	margin: auto;
	margin-bottom: 79px;

	max-width: 1400px;

	@include mediaMax(590px) {
		margin-bottom: 44px;
	}
}

.cards {
	display: flex;
	flex-wrap: wrap;
	column-gap: 25px;
	row-gap: 37px;

	max-width: 1055px;

	@include mediaMax(1450px) {
		align-items: center;
		justify-content: center;
	}
}

.card {
	border-radius: 45px;
	background: #252332;

	width: 515px;
	height: 172px;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 23px;

	@include mediaMax(590px) {
		height: fit-content;
		width: 100%;
		max-width: 341px;

		padding: 30px 17px;

		gap: 19px;
	}
}

.cardTitle {
	font-family: $mainFontFamily;
	font-weight: 700;
	font-size: 20px;
	line-height: 124%;
	text-transform: uppercase;
	text-align: center;
	color: #fff;
}

.cardDescription {
	font-family: $mainFontFamily;
	font-weight: 300;
	font-size: 16px;
	line-height: 137%;
	text-align: center;
	color: #fff;

	opacity: 0.85;

	max-width: 463px;
}

.mainContent {
	display: flex;

	max-height: 530px;

	position: relative;

	@include mediaMax(1450px) {
		flex-direction: column;
		align-items: center;
		justify-content: center;

		max-height: fit-content;
	}
}

.cuteMonster {
	position: absolute;
	top: -129px;
	right: -107px;

	@include mediaMax(1450px) {
		position: relative;
		top: auto;
		right: auto;

		@include mediaMax(590px) {
			height: 365px;
			width: auto;

			margin-top: -48px;
		}
	}
}

.cuteMonsterCore {
	top: -72px;

	@include mediaMax(1450px) {
		position: relative;
		top: 0;
		right: auto;

		@include mediaMax(590px) {
			height: 365px;
			width: auto;

			margin-top: -48px;
		}
	}
}
