@import "/src/app/styles";

.content {
	font-family: $mainFontFamily;
	font-weight: 700;
	font-size: 72px;
	line-height: 124%;
	color: #fff;

	margin-bottom: 63px;

	display: flex;
	justify-content: center;
}

.mainContent {
	max-width: 1352px;

	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;

	padding: 0 34px 0 43px;
	margin: 0 24px;

	@include mediaMax(1360px) {
		padding: 31px 34px 19px 43px;
	}

	@include mediaMax(720px) {
		padding: 31px 17px 19px 6px;
	}

	@include mediaMax(460px) {
		width: 341px;
	}
}

.titleBlock {
	max-width: 955px;

	font-family: $mainFontFamily;
	font-weight: 700;
	font-size: 72px;
	line-height: 124%;
	color: #fff;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	text-align: center;

	@include mediaMax(740px) {
		font-size: 60px;
	}

	@include mediaMax(570px) {
		font-size: 40px;
	}
}

.title {
	margin-bottom: 40px;

	@include mediaMax(570px) {
		max-width: 318px;

		margin-bottom: 18px;
	}
}

.coreBlock {
	border-radius: 10px;
	width: 307px;
	height: 98px;

	background: linear-gradient(90deg, #ff9211 0%, #ffcb11 100%);

	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;

	@include mediaMax(570px) {
		width: 169px;
		height: 47px;

		margin-left: 6px;

		font-size: 36px;

		& > img {
			width: 134px;
			height: 35px;
		}

		& > div {
			margin-right: 14px;
		}
	}
}
