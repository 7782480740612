@import "/src/app/styles";

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	margin-bottom: 23px;
}

.title {
	font-family: $mainFontFamily;
	font-weight: 700;
	font-size: 48px;
	line-height: 124%;
	text-align: center;
	color: #fff;

	margin-bottom: 63px;
	padding: 0 24px;

	@include mediaMax(590px) {
		font-size: 24px;

		margin-bottom: 36px;
	}
}

.cards {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: 36px;

	margin-bottom: 47px;
	padding: 0 26px;
}
