@import "/src/app/styles";

.content {
	width: 48px;
	height: 48px;

	display: grid;
	justify-items: center;
	align-items: center;

	position: relative;
}

.border {
	cursor: pointer;

	position: absolute;

	top: 0;
	left: 0;

	width: 48px;
	height: 48px;
}