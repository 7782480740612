@import "/src/app/styles";

.content {
	display: flex;
	flex-direction: column;
	//align-items: start;
	justify-content: center;
	align-items: center;

	@include mediaMax(1450px) {
		align-items: center;
	}
}

.title {
	font-family: $mainFontFamily;
	font-weight: 700;
	font-size: 48px;
	line-height: 124%;
	color: #fff;

	margin-bottom: 63px;
	padding-top: 87px;
	padding-left: 28px;
	padding-right: 24px;

	width: 100%;
	max-width: 1400px;

	@include mediaMax(1370px) {
		display: flex;
		justify-content: center;
	}

	@include mediaMax(590px) {
		font-size: 24px;

		margin-bottom: 51px;
		padding-top: 61px;
	}
}

.cards {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: 36px;

	margin-bottom: 104px;
	padding: 0 26px;
}

.card {
	width: 427px;
	height: 264px;

	background-size: cover;
	background-repeat: no-repeat;

	position: relative;

	cursor: pointer;

	@include mediaMax(590px) {
		width: 341px;
		height: 210px;
	}
}

.cardCore {
	width: 427px;
	height: 367px;

	border-radius: 45px;

	@include mediaMax(590px) {
		width: 341px;
		height: 302px;
	}
}

.card1 {
	background-image: url("../../assets/img/svg/shadowPixudi.svg");
}

.card2 {
	background-image: url("../../assets/img/svg/shadowStars.svg");
}

.card3 {
	background-image: url("../../assets/img/svg/shadowDypians.svg");
}

.background {
	position: absolute;
	top: 0;

	@include mediaMax(590px) {
		width: 341px;
		height: 100px;
	}
}

.backgroundCore {
	height: auto;
	width: 427px;

	border-top-right-radius: 45px;
	border-top-left-radius: 45px;

	@include mediaMax(590px) {
		width: 341px;
	}
}

.cardContent {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 14px;
	margin-top: 175px;

	@include mediaMax(590px) {
		margin-top: 133px;

		gap: 10px;
	}
}

.cardContentCore {
	margin-top: 236px;

	@include mediaMax(590px) {
		margin-top: 180px;
	}
}

.cardDescription {
	font-family: $mainFontFamily;
	font-weight: 300;
	font-size: 16px;
	line-height: 137%;
	text-align: center;
	color: #fff;

	opacity: 0.85;

	max-width: 335px;

	@include mediaMax(590px) {
		font-size: 14px;

		max-width: 267px;
	}
}

.playNow {
	font-family: $mainFontFamily;
	font-weight: 900;
	font-size: 16px;
	line-height: 124%;
	text-align: center;
	color: #fff;
}

.logo {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	border: 2px solid rgba(255, 255, 255, 0.11);
	border-radius: 17px;
	background: #17161f;

	width: 157px;
	height: 55px;

	display: flex;
	align-items: center;
	justify-content: center;

	@include mediaMax(590px) {
		width: 125px;
		height: 43px;
	}
}

.logoCore {
	// height: 40px;
	// width: auto;

	& > img {
		max-width: 157px;
		width: auto;
		max-height: 55px;
		height: 40px;

		border-radius: 10px;

		@include mediaMax(590px) {
			max-width: 125px;
			max-height: 43px;
		}
	}
}

.pixudiLogo {
	@include mediaMax(590px) {
		height: 50px;
		width: auto;
	}
}

.starsLogo {
	@include mediaMax(590px) {
		height: 34px;
		width: auto;
	}
}

.dypiansLogo {
	@include mediaMax(590px) {
		height: 34px;
		width: auto;
	}
}

.titleGame {
	font-family: $mainFontFamily;
	font-weight: 700;
	font-size: 48px;
	line-height: 124%;
	color: #fff;

	margin-bottom: 102px;

	text-align: center;

	@include mediaMax(590px) {
		font-size: 24px;

		margin-bottom: 36px;
		padding: 0 14px;
	}
}
