@import "/src/app/styles";

.card {
	border-radius: 45px;
	background: #252332;
    background-size: cover;

	width: 427px;
	height: 433px;

	padding-top: 34px;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;

    @include mediaMax(590px) {
        width: 341px;
        height: 345px;

        padding-top: 20px;
    }
}

.cardTitle {
	font-family: $mainFontFamily;
	font-weight: 700;
	font-size: 32px;
	line-height: 124%;
	text-align: center;
	color: #fff;

	margin-bottom: 35px;

    @include mediaMax(590px) {
        font-size: 24px;

        margin-bottom: 20px;
    }
}

.cardDescription {
	font-family: $mainFontFamily;
	font-weight: 300;
	font-size: 16px;
	line-height: 137%;
	text-align: center;
	color: #fff;

	opacity: 0.85;

	margin-bottom: 20px;

	max-width: 335px;

    @include mediaMax(590px) {
        max-width: 295px;

        margin-bottom: 16px;
    }
}

.arrow {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;

	font-family: $mainFontFamily;
	font-weight: 500;
	font-size: 18px;
	line-height: 137%;
	text-align: center;
	color: #fff;

    cursor: pointer;

	position: relative;
	z-index: 2;
}