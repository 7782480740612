/*! normalize.css 2012-07-07T09:50 UTC - http://github.com/necolas/normalize.css */

/* ==========================================================================
   HTML5 display definitions
   ========================================================================== */

/*
 * Corrects `block` display not defined in IE6/7/8/9 & FF3.
 */

*,
*::before,
*::after {
	box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
	display: block;
}

/*
  * Corrects `inline-block` display not defined in IE6/7/8/9 & FF3.
  */

audio,
canvas,
video {
	display: inline-block;
	*display: inline;
	*zoom: 1;
}

//.canvas {
//  transform: translateZ(0);
//  -webkit-transform: translateZ(0);
//}

/*
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS5 devices.
 */

audio:not([controls]) {
	display: none;
	height: 0;
}

/*
  * Addresses styling for `hidden` attribute not present in IE7/8/9, FF3, S4.
  * Known issue: no IE6 support.
  */

[hidden] {
	display: none;
}

/* ==========================================================================
    Base
    ========================================================================== */

/*
  * 1. Corrects text resizing oddly in IE6/7 when body `font-size` is set using
  *    `em` units.
  * 2. Prevents iOS text size adjust after orientation change, without disabling
  *    user zoom.
  */

html {
	font-size: 100%; /* 1 */
	-webkit-text-size-adjust: 100%; /* 2 */
	-ms-text-size-adjust: 100%; /* 2 */
	height: 100%;
}

/*
  * Addresses `font-family` inconsistency between `textarea` and other form
  * elements.
  */

html,
button,
input,
select,
textarea {
	font-family: sans-serif;
}

/*
  * Addresses margins handled incorrectly in IE6/7.
  */

body {
	padding: 0px;
	margin: 0px;
	height: 100%;
	/* min-height: 100vh; */
	/* border: 10px solid #000; */
}

/* ==========================================================================
    Links
    ========================================================================== */

/*
  * Addresses `outline` inconsistency between Chrome and other browsers.
  */

a:focus {
	outline: thin dotted;
}
a:active,
a:hover {
	outline: 0;
}

/* ==========================================================================
    Typography
    ========================================================================== */

/*
  * Addresses font sizes and margins set differently in IE6/7.
  * Addresses font sizes within `section` and `article` in FF4+, Chrome, S5.
  */

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-weight: normal;
}

/*
  * Addresses styling not present in IE7/8/9, S5, Chrome.
  */

abbr[title] {
	border-bottom: 1px dotted;
}

/*
  * Addresses style set to `bolder` in FF3+, S4/5, Chrome.
  */

b,
strong {
	font-weight: bold;
}

blockquote {
	margin: 1em 40px;
}

/*
  * Addresses styling not present in S5, Chrome.
  */

dfn {
	font-style: italic;
}

/*
  * Addresses styling not present in IE6/7/8/9.
  */

mark {
	background: #ff0;
	color: #000;
}

/*
  * Addresses margins set differently in IE6/7.
  */

p,
pre {
	margin: 1em 0;
}

/*
  * Corrects font family set oddly in IE6, S4/5, Chrome.
  * en.wikipedia.org/wiki/User:Davidgothberg/Test59
  */

code,
kbd,
pre,
samp {
	font-family: monospace, serif;
	_font-family: "courier new", monospace;
	font-size: 1em;
}

/*
  * Improves readability of pre-formatted text in all browsers.
  */

pre {
	white-space: pre;
	white-space: pre-wrap;
	word-wrap: break-word;
}

/*
  * Addresses CSS quotes not supported in IE6/7.
  */

q {
	quotes: none;
}

/*
  * Addresses `quotes` property not supported in S4.
  */

q:before,
q:after {
	content: "";
	content: none;
}

small {
	font-size: 75%;
}

/*
  * Prevents `sub` and `sup` affecting `line-height` in all browsers.
  * gist.github.com/413930
  */

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup {
	top: -0.5em;
}

sub {
	bottom: -0.25em;
}

/* ==========================================================================
    Lists
    ========================================================================== */

/*
  * Addresses margins set differently in IE6/7.
  */

dl,
menu,
ol,
ul {
	margin: 1em 0;
	list-style: none;
}

dd {
	margin: 0 0 0 40px;
}

/*
  * Addresses paddings set differently in IE6/7.
  */

menu,
ol,
ul {
	padding: 0 0 0 0;
}

/*
  * Corrects list images handled incorrectly in IE7.
  */

nav ul,
nav ol {
	list-style: none;
	list-style-image: none;
}

/* ==========================================================================
    Embedded content
    ========================================================================== */

/*
  * 1. Removes border when inside `a` element in IE6/7/8/9, FF3.
  * 2. Improves image quality when scaled in IE7.
  *    code.flickr.com/blog/2008/11/12/on-ui-quality-the-little-things-client-side-image-resizing/
  */

img {
	border: 0; /* 1 */
	-ms-interpolation-mode: bicubic; /* 2 */
}

/*
  * Corrects overflow displayed oddly in IE9.
  */

svg:not(:root) {
	overflow: hidden;
}

/* ==========================================================================
    Figures
    ========================================================================== */

/*
  * Addresses margin not present in IE6/7/8/9, S5, O11.
  */

figure {
	margin: 0;
}

/* ==========================================================================
    Forms
    ========================================================================== */

/*
  * Corrects margin displayed oddly in IE6/7.
  */

form {
	margin: 0;
}

/*
  * Define consistent border, margin, and padding.
  */

fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}

/*
  * 1. Corrects color not being inherited in IE6/7/8/9.
  * 2. Corrects text not wrapping in FF3.
  * 3. Corrects alignment displayed oddly in IE6/7.
  */

legend {
	border: 0; /* 1 */
	padding: 0;
	white-space: normal; /* 2 */
	*margin-left: -7px; /* 3 */
}

/*
  * 1. Corrects font size not being inherited in all browsers.
  * 2. Addresses margins set differently in IE6/7, FF3+, S5, Chrome.
  * 3. Improves appearance and consistency in all browsers.
  */

button,
input,
select,
textarea {
	font-size: 100%; /* 1 */
	margin: 0; /* 2 */
	vertical-align: baseline; /* 3 */
	*vertical-align: middle; /* 3 */
}

/*
  * Addresses FF3/4 setting `line-height` on `input` using `!important` in the
  * UA stylesheet.
  */

button,
input {
	line-height: normal;
}

/*
  * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
  *    and `video` controls.
  * 2. Corrects inability to style clickable `input` types in iOS.
  * 3. Improves usability and consistency of cursor style between image-type
  *    `input` and others.
  * 4. Removes inner spacing in IE7 without affecting normal text inputs.
  *    Known issue: inner spacing remains in IE6.
  */

button,
 html input[type="button"], /* 1 */
 input[type="reset"],
 input[type="submit"] {
	-webkit-appearance: button; /* 2 */
	cursor: pointer; /* 3 */
	*overflow: visible; /* 4 */
}

/*
  * Re-set default cursor for disabled elements.
  */

button[disabled],
input[disabled] {
	cursor: default;
}

/*
  * 1. Addresses box sizing set to content-box in IE8/9.
  * 2. Removes excess padding in IE8/9.
  * 3. Removes excess padding in IE7.
  *    Known issue: excess padding remains in IE6.
  */

input[type="checkbox"],
input[type="radio"] {
	box-sizing: border-box; /* 1 */
	padding: 0; /* 2 */
	*height: 13px; /* 3 */
	*width: 13px; /* 3 */
}

/*
  * 1. Addresses `appearance` set to `searchfield` in S5, Chrome.
  * 2. Addresses `box-sizing` set to `border-box` in S5, Chrome (include `-moz`
  *    to future-proof).
  */

input[type="search"] {
	-webkit-appearance: textfield; /* 1 */
	-moz-box-sizing: content-box;
	-webkit-box-sizing: content-box; /* 2 */
	box-sizing: content-box;
}

/*
  * Removes inner padding and search cancel button in S5, Chrome on OS X.
  */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

/*
  * Removes inner padding and border in FF3+.
  */

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

/*
  * 1. Removes default vertical scrollbar in IE6/7/8/9.
  * 2. Improves readability and alignment in all browsers.
  */

textarea {
	overflow: auto; /* 1 */
	vertical-align: top; /* 2 */
}

/* ==========================================================================
    Tables
    ========================================================================== */

/*
  * Remove most spacing between table cells.
  */

table {
	border-collapse: collapse;
	border-spacing: 0;
}
#modal {
}
