@import "/src/app/styles";

.content {
	border-radius: 45px;
	background: #252332;
	background-image: url("../../assets/img/svg/shadow.svg");
	background-size: cover;
	background-repeat: no-repeat;

	width: 1352px;
	height: 338px;

	margin-bottom: 31px;
	padding: 0 56px 0 68px;

	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;

	@include mediaMax(1390px) {
		width: 100%;
	}

	@include mediaMax(970px) {
		height: fit-content;
		width: auto;

		justify-content: center;
		align-items: center;
		gap: 52px;

		padding-top: 36px;
		padding-bottom: 8px;
	}

	@include mediaMax(590px) {
		background-image: url("../../assets/img/svg/shadowMobile.svg");

		width: 341px;
		height: 552px;
	}
}

.contentCore {
	background-image: url("../../assets/img/png/mountain.png");

	@include mediaMax(970px) {
		width: auto;
		height: 552px;
	}
}

.wrapper {
	display: flex;
	justify-content: center;
	
	@include mediaMax(1390px) {
		padding: 0 26px;
	}

	@include mediaMax(970px) {
		display: flex;
		justify-content: center;
	}
}

.leftBlock {
	display: flex;
	flex-direction: column;
	gap: 30px;

	@include mediaMax(970px) {
		justify-content: center;
		align-items: center;
	}
}

.title {
	font-family: $mainFontFamily;
	font-weight: 700;
	font-size: 48px;
	line-height: 124%;
	color: #fff;

	@include mediaMax(590px) {
		font-size: 24px;
	}
}

.description {
	font-family: $mainFontFamily;
	font-weight: 300;
	font-size: 16px;
	line-height: 137%;
	color: #fff;

	opacity: 0.85;

	@include mediaMax(590px) {
		text-align: center;
	}
}

.btns {
	display: flex;
	gap: 9px;
}

.cave {
	@include mediaMax(590px) {
		width: 290px;
		height: 290px;

		margin-top: -8px;
	}
}
