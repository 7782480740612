@import "normalize.scss";
@import "media";
@import "main";

:root {
	background: #17161f;
}

body {
	margin: 0;
	font-family: $mainFontFamily;
	height: 100%;
}

body {
	overflow-x: hidden;
}

button {
	cursor: pointer;
}

.container {
	max-width: $screenEx;
	margin: 0 auto;

	@include mediaMax($screenEx) {
		margin: 0 8%;
	}
}

header {
	grid-area: header;
}

.disabled {
	opacity: 0.5;
	pointer-events: none;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap");
